







import Vue from 'vue'
export default Vue.extend({
    props: {
        price: {
            type: Number,
            required: true
        },
        quantity: Number,
        alignRight: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        priceFixed(): number {
            return Number.isInteger(this.price) ? this.price : parseFloat(this.price.toFixed(2))
        },
        totalPrice(): number {
            const total = this.quantity * this.priceFixed
            return Number.isInteger(total) ? total : parseFloat(total.toFixed(2))
        }
    }
})
