import Vue from 'vue'
import moment from 'moment'
import Formats from '@/constants/formats'

import {
    formatLocalDate
} from '@/modules/date'

export default Vue.extend({
    methods: {
        $_formatDate(date: string): string {
            return formatLocalDate(date, Formats.DisplayDate)
        }
    }
})
