

















































































import { PropType } from "vue"
import mixins from 'vue-typed-mixins'

import { Location } from 'vue-router'

import dateMixin from "@/mixins/dateMixin"

import { HCard, HIcon, HBtn } from "@happytal/bo-ui-library"
import PriceWithQuantity from "@/components/PriceWithQuantity.vue"
import SeparatorLine from "@/components/SeparatorLine.vue"

export default mixins(dateMixin).extend({
    components: {
        HCard,
        HIcon,
        HBtn,
        PriceWithQuantity,
        SeparatorLine
    },

    props: {
        id: String,
        author: String,
        creationDate: String,
        imgUrl: String,
        title: {
            type: String,
            required: true,
        },
        route: {
            type: Object as PropType<Location>
        },
        description: {
            type: String,
            required: true,
        },
        quantity: Number,
        orderType: String,
        frequencyLabel: String,
        price: Number,
        isSuggestionOpen: Boolean,
        isSuggestionProduct: Boolean,
        isSuggestionService: Boolean,
        deleteEnabled: {
            type: Boolean,
            default: true
        },
        unavailable: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        thumbnailTag(): string {
            return this.route && !this.unavailable ? 'router-link' : 'div'
        },
        isDeleteEnabled(): boolean {
            return this.deleteEnabled && !this.$aclChecker.some(this.$acl, ['isTutor', 'isFamily'])
        }
    },

    methods: {
        onDeleteBtnClick(e: object): void {
            this.$emit('delete')
        }
    }
})
